import * as React from "react";
import styled from "styled-components";

export class Favorites extends React.Component {
    render() {
        return (
            <FavrotiesWrap>
                my favroties
            </FavrotiesWrap>
        );
    };

}

const FavrotiesWrap = styled.div`
margin-left: 280px;
`
